import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import uniqueId from 'lodash.uniqueid';
const badge = 'more/badges';
const costSecurity = {
    name: 'costSecurity',
    serviceName: 'serviceCostSecurity',
    serviceTitle: 'Безопасность расходов',
    id: uniqueId(),
    title: (_jsxs(_Fragment, { children: ["\u0411\u0435\u0437\u043E\u043F\u0430\u0441\u043D\u043E\u0441\u0442\u044C ", _jsx("br", {}), " \u0440\u0430\u0441\u0445\u043E\u0434\u043E\u0432"] })),
    link: '#',
    icon: {
        mobile: { alt: 'costSecurity', src: { fileName: 'cost-security-bage.png', directory: badge } },
    },
    slider: {
        parent: [
            {
                title: 'Безопасность расходов',
                subtitle: 'Без лишних затрат по тарифу',
                text: (_jsx(_Fragment, { children: "\u0421\u0435\u0440\u0432\u0438\u0441\u044B \u043F\u043E\u0434\u043F\u0438\u0441\u043A\u0438 \u041C\u0422\u0421 Junior \u043D\u0435\u00A0\u0440\u0430\u0441\u0445\u043E\u0434\u0443\u044E\u0442 \u0438\u043D\u0442\u0435\u0440\u043D\u0435\u0442-\u0442\u0440\u0430\u0444\u0438\u043A \u043F\u043E\u00A0\u0442\u0430\u0440\u0438\u0444\u0443 \u0420\u0435\u0431\u0451\u043D\u043A\u0430. \u041C\u044B \u043E\u0433\u0440\u0430\u043D\u0438\u0447\u0438\u043B\u0438 \u0437\u0432\u043E\u043D\u043A\u0438 \u0438 SMS \u043D\u0430 \u043F\u043B\u0430\u0442\u043D\u044B\u0435 \u043A\u043E\u0440\u043E\u0442\u043A\u0438\u0435 \u043D\u043E\u043C\u0435\u0440\u0430 \u0438\u00A0\u043F\u043E\u0434\u043A\u043B\u044E\u0447\u0435\u043D\u0438\u0435 \u043F\u043E\u0434\u043F\u0438\u0441\u043E\u043A. \u0415\u0441\u043B\u0438 \u043F\u0430\u043A\u0435\u0442 \u0438\u043D\u0442\u0435\u0440\u043D\u0435\u0442\u0430 \u043F\u043E \u0442\u0430\u0440\u0438\u0444\u0443 \u0420\u0435\u0431\u0451\u043D\u043A\u0430 \u0437\u0430\u043A\u043E\u043D\u0447\u0438\u0442\u0441\u044F, \u043E\u043D \u043D\u0435 \u0432\u044B\u0439\u0434\u0435\u0442 \u043F\u043B\u0430\u0442\u043D\u043E \u0432\u00A0\u0438\u043D\u0442\u0435\u0440\u043D\u0435\u0442" })),
            },
        ],
        child: [
            {
                title: 'Безопасность расходов',
                subtitle: 'Без лишних трат',
                text: 'С МТС Junior не расходуется трафик на сервисы внутри подписки и не списывается плата за SMS, звонки на короткие номера, а еще когда заканчивается интернет на тарифе',
            },
        ],
    },
};
export const spamBlocking = {
    name: 'spamBlocking',
    serviceName: 'serviceSpamBlocking',
    id: uniqueId(),
    serviceTitle: _jsx(_Fragment, { children: "\u0417\u0430\u0449\u0438\u0442\u043D\u0438\u043A \u043E\u0442\u00A0\u0441\u043F\u0430\u043C\u0435\u0440\u043E\u0432" }),
    title: (_jsxs(_Fragment, { children: ["\u0417\u0430\u0449\u0438\u0442\u043D\u0438\u043A ", _jsx("br", {}), " \u043E\u0442\u00A0\u0441\u043F\u0430\u043C\u0435\u0440\u043E\u0432"] })),
    link: '#',
    icon: {
        mobile: {
            alt: 'spamBlocking',
            src: { fileName: 'spam-blocking-bage.png', directory: badge },
        },
    },
    price: '90 ₽',
    slider: {
        parent: [
            {
                title: 'Защитник от спамеров',
                subtitle: 'Лишние звонки не пройдут',
                text: 'Подозрительные звонки не поступят на телефон Ребёнка. Услуга работает даже без интернета и установки дополнительных приложений',
            },
        ],
        child: [
            {
                title: 'Защитник от спамеров',
                subtitle: 'Лишние звонки не пройдут',
                text: 'Разговаривай только со знакомыми людьми — звонки с незнакомых или подозрительных номеров мы заблокируем',
            },
        ],
    },
};
const unlimitedMessengers = {
    name: 'unlimitedMessengers',
    serviceName: 'serviceUnlimitedMessengers',
    id: uniqueId(),
    serviceTitle: 'Безлимитные мессенджеры',
    title: (_jsxs(_Fragment, { children: ["\u0411\u0435\u0437\u043B\u0438\u043C\u0438\u0442\u043D\u044B\u0435 ", _jsx("br", {}), " \u043C\u0435\u0441\u0441\u0435\u043D\u0434\u0436\u0435\u0440\u044B"] })),
    link: '#',
    icon: {
        mobile: { alt: 'unlimitedMessengers', src: { fileName: 'unlimited-messengers-bage.png', directory: badge } },
    },
    price: '39 ₽',
    slider: {
        parent: [
            {
                title: 'Безлимитные мессенджеры',
                subtitle: 'Общение с семьёй и друзьями',
                text: 'Ребёнок сможет писать и звонить вам в любой момент во всех популярных мессенджерах: WhatsApp, Telegram, Discord, Snapchat, Viber, Skype, ICQ, МТС Connect',
            },
        ],
        child: [
            {
                title: 'Безлимитные мессенджеры',
                subtitle: 'Общение с семьей и друзьями',
                text: 'Переписывайся, звони друзьям и родным без ограничений во всех популярных мессенджерах: WhatsApp, Telegram, Discord, Snapchat, Viber, Skype, ICQ, МТС Connect',
            },
        ],
    },
};
const unlimitedLikee = {
    name: 'unlimitedLikee',
    serviceName: 'serviceUnlimitedLikee',
    id: uniqueId(),
    serviceTitle: _jsx(_Fragment, { children: "\u0411\u0435\u0437\u043B\u0438\u043C\u0438\u0442 \u043D\u0430\u00A0Likee" }),
    title: (_jsxs(_Fragment, { children: ["\u0411\u0435\u0437\u043B\u0438\u043C\u0438\u0442 ", _jsx("br", {}), " \u043D\u0430\u00A0Likee"] })),
    link: '#',
    icon: {
        mobile: { alt: 'unlimitedLikee', src: { fileName: 'unlimited-likee-bage.png', directory: badge } },
        desktop: { alt: 'unlimitedLikee', src: { fileName: 'unlimited-likee-white-bage.png', directory: badge } },
    },
    slider: {
        parent: [
            {
                title: `Безлимит на Likee`,
                subtitle: 'Экономия трафика в популярной соцсети',
                text: 'Ребёнок сможет общаться с друзьями, смотреть клипы и устраивать стримы без дополнительных расходов на интернет',
            },
        ],
        child: [
            {
                title: `Безлимит на Likee`,
                subtitle: 'Стримы и клипы со звёздами',
                text: 'Будь в тренде, общайся с друзьями и участвуй в гивах от блогеров и звёзд в Likee',
            },
        ],
    },
};
export const unlimitedSocial = {
    name: 'unlimitedSocial',
    serviceName: 'serviceUnlimitedSocial',
    id: uniqueId(),
    serviceTitle: 'Безлимитные cоц.сети',
    title: (_jsxs(_Fragment, { children: ["\u0411\u0435\u0437\u043B\u0438\u043C\u0438\u0442\u043D\u044B\u0435 ", _jsx("br", {}), " c\u043E\u0446.\u0441\u0435\u0442\u0438"] })),
    link: '#',
    icon: {
        mobile: {
            alt: 'unlimitedSocial',
            src: { fileName: 'unlimited-social-bage.png', directory: badge },
        },
    },
    price: '69 ₽',
    slider: {
        parent: [
            {
                title: 'Безлимитные соц.сети',
                subtitle: 'ВКонтакте и Одноклассники',
                text: 'Ребёнок сможет общаться с друзьями и родными в соцсетях, не тратя интернет',
            },
        ],
        child: [
            {
                title: 'Безлимитные соц.сети',
                subtitle: 'Для клипов, сообщений и сердечек',
                text: 'Пользуйся ВКонтакте и Одноклассниками, сколько хочешь — интернет при этом не расходуется',
            },
        ],
    },
};
const alwaysInTouch = {
    name: 'alwaysInTouch',
    serviceName: 'serviceAlwaysInTouch',
    id: uniqueId(),
    serviceTitle: _jsx(_Fragment, { children: "\u0412\u0441\u0435\u0433\u0434\u0430 \u043D\u0430\u00A0\u0441\u0432\u044F\u0437\u0438" }),
    title: (_jsxs(_Fragment, { children: ["\u0412\u0441\u0435\u0433\u0434\u0430 ", _jsx("br", {}), " \u043D\u0430\u00A0\u0441\u0432\u044F\u0437\u0438"] })),
    link: '#',
    icon: {
        mobile: { alt: 'alwaysInTouch', src: { fileName: 'always-in-touch-bage.png', directory: badge } },
    },
    slider: {
        parent: [
            {
                title: 'Всегда на связи',
                subtitle: 'Возможность дозвониться до Ребёнка',
                text: (_jsx(_Fragment, { children: "\u0414\u0430\u0436\u0435 \u043F\u0440\u0438 \u043D\u0443\u043B\u0435 \u043D\u0430 \u0431\u0430\u043B\u0430\u043D\u0441\u0435 \u0442\u0435\u043B\u0435\u0444\u043E\u043D\u0430 \u0420\u0435\u0431\u0451\u043D\u043A\u0430 \u0432\u044B \u0432\u0441\u0435\u0433\u0434\u0430 \u0441\u043C\u043E\u0436\u0435\u0442\u0435 \u0434\u043E \u043D\u0435\u0433\u043E \u0434\u043E\u0437\u0432\u043E\u043D\u0438\u0442\u044C\u0441\u044F \u0438\u043B\u0438 \u043E\u043D \u0441\u0430\u043C \u0441\u043C\u043E\u0436\u0435\u0442 \u0432\u0430\u043C \u043D\u0430\u043F\u0438\u0441\u0430\u0442\u044C \u0432 \u043C\u0435\u0441\u0441\u0435\u043D\u0434\u0436\u0435\u0440\u044B" })),
            },
        ],
        child: [
            {
                title: 'Всегда на связи',
                subtitle: 'В любой момент доступен',
                text: 'Ты всегда можешь написать родителям или близким в мессенджеры, даже если на балансе нет денег',
            },
        ],
    },
};
export const moreCardList = {
    parent: [spamBlocking, alwaysInTouch, costSecurity, unlimitedMessengers, unlimitedSocial, unlimitedLikee],
    child: [unlimitedLikee, unlimitedMessengers, unlimitedSocial, spamBlocking, alwaysInTouch, costSecurity],
};
export const titleConfig = {
    parent: (_jsxs(_Fragment, { children: ["\u0414\u043E\u0441\u0442\u0443\u043F\u043D\u043E \u0431\u043E\u043B\u044C\u0448\u0435 \u0434\u043B\u044F\u00A0\u0440\u0435\u0431\u0451\u043D\u043A\u0430 ", _jsx("br", {}), " \u0441\u00A0\u043D\u043E\u043C\u0435\u0440\u043E\u043C \u041C\u0422\u0421"] })),
    child: _jsx(_Fragment, { children: "\u0421\u00A0\u043D\u043E\u043C\u0435\u0440\u043E\u043C \u041C\u0422\u0421 \u0434\u043E\u0441\u0442\u0443\u043F\u043D\u043E \u0431\u043E\u043B\u044C\u0448\u0435" }),
};
export const modalDescriptionConfig = {
    parent: 'Бесплатно в подписке МТС Junior',
    child: 'Бесплатно с МТС Junior',
};
